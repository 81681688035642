import { defineStore } from 'pinia'
import { Measure } from "@/measures/model/measure";
import {CalculatedMeasureOverview} from "@/measures/model/calculatedMeasureOverview";
import SortDirection from "@/common/table/types/sortDirection";
import SortBy from "@/measures/model/sortBy";

export const measuresStore = defineStore('measures', {
    state() {
        return {
            justCreatedNewMeasure: false,
            justEditedMeasure: false,
            justDeletedMeasure: false,
            currentlySelectedMeasure: null as Measure | null,
            currentlyAddingNewMeasure: false,
            calculatedMeasures: [{
                propertyKey: '',
                measures: [{
                    key: '',
                    type: '',
                    name: '',
                    cost: 0,
                    saving: 0,
                    amortizationPeriod: 0,
                    isBEG: false
                }]
            }],
            sortDirection: SortDirection.ASCENDING,
            sortAttribute : SortBy.TYPE,

        }
    },
    getters: {
        getJustCreatedNewMeasure: (state): boolean => {
          return state.justCreatedNewMeasure;
        },
        getJustEditedMeasure: (state): boolean => {
            return state.justEditedMeasure;
        },
        getJustDeletedMeasure: (state): boolean => {
            return state.justDeletedMeasure;
        },
        getCurrentlySelectedMeasure: (state): Measure | null => {
            return state.currentlySelectedMeasure;
        },
        getCurrentlyAddingMeasure: (state): boolean => {
            return state.currentlyAddingNewMeasure;
        },
        // getCalculatedMeasuresByPropertyKey: (state): CalculatedMeasureOverview[] => {
        //     return state.calculatedMeasures;
        // }
        getSortDirection: (state): SortDirection => {
            return state.sortDirection;
        },
        getSortAttribute: (state): SortBy => {
            return state.sortAttribute;
        }
    },
    actions: {
        setJustCreatedNewMeasure(newState: boolean) {
            this.justCreatedNewMeasure = newState;
        },
        setJustEditedMeasure(newState: boolean) {
            this.justEditedMeasure = newState;
        },
        setJustDeletedMeasure(newState: boolean) {
            this.justDeletedMeasure = newState;
        },
        setCurrentlySelectedMeasure(newState: Measure | null) {
            if (newState) {
                this.currentlySelectedMeasure = {
                    key: newState.key,
                    type: convertToLocaleType(newState.type),
                    name: newState.name,
                    description: newState.description,
                    insulationStrength: newState.insulationStrength,
                    producerEffortNumber: newState.producerEffortNumber,
                    heatLoss: newState.heatLoss,
                    storageVolume: newState.storageVolume,
                    thermalConductionGroup: newState.thermalConductionGroup,
                    uwValue: newState.uwValue,
                    insulationConstructionType: newState.insulationConstructionType,
                    costPerUnit: newState.costPerUnit,
                    fixedCost: newState.fixedCost,
                    unit: newState.unit,
                    saving: newState.saving
                }
            }
        },
        setCurrentlyAddingMeasure(newState: boolean) {
            this.currentlyAddingNewMeasure = newState;
        },
        clear() {
            this.$reset();
        },
        updateCalculatedMeasures(newPropertyKey: string, newCalculatedMeasures: CalculatedMeasureOverview[]) {
            this.calculatedMeasures.push({
                propertyKey: newPropertyKey,
                measures: newCalculatedMeasures
            });
        },
        clearCalculatedMeasures() {
            this.calculatedMeasures.length = 0;
        },
        retrieveCalculatedMeasureByPropertyKey(propertyKey: string) {
            const filteredMeasures = this.calculatedMeasures.filter(calculatedMeasure => calculatedMeasure.propertyKey === propertyKey);
            return filteredMeasures.flatMap(item => item.measures);
        },
        setSortDirection(sortDirection: SortDirection) {
            this.sortDirection = sortDirection;
        },
        setSortAttribute(sortAttribute: SortBy) {
            this.sortAttribute = sortAttribute;
        }
    }
});

function convertToLocaleType(type: string) {
    switch (type) {
        case 'Aussenwand: Kerndaemmung':
            return 'Außenwand: Kerndämmung';
        case 'Aussenwand: Aussendaemmung':
            return 'Außenwand: Außendämmung';
        case 'Aussenwand: Innendaemmung':
            return 'Außenwand: Innendämmung';
        case 'Dach: Aussendaemmung':
            return 'Dach: Außendämmung';
        case 'Dach: Innendaemmung':
            return 'Dach: Innendämmung';
        case 'Decke: Daemmung':
            return 'Decke: Dämmung';
        case 'Fenster/Tueren: Austausch':
            return 'Fenster/Türen: Austausch';
        default: return type;
    }
}